import styled from "styled-components"
import { spacing } from "styles/spacing"
import { breakpoint } from "styles/breakpoints"

export const Container = styled.div`
  padding: 0 ${spacing.sm}px;
  @media ${breakpoint.md} {
    padding: 0;
  }
`

export const StyledContainer = styled(Container)`
  @media ${breakpoint.md} {
    padding: ${spacing.md}px;
  }
`
export const ContentContainer = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  & > :not(last-child) {
    margin-bottom: ${spacing.xl}px;
  }
  @media ${breakpoint.md} {
    padding: ${spacing.xl}px;
  }
  @media ${breakpoint.xl} {
    & > :not(last-child) {
      margin-bottom: ${spacing.xxxl}px;
    }
  }
`
