import React, { FC } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { spacing } from "../styles/spacing"
import Menu from "./Menu"
import { LogoIcon } from "./Icons"
import Social from "./Social"
import { breakpoint } from "../styles/breakpoints"
import { fadeIn } from "../styles/keyframes"
import { headerHeight } from "styles/variables"

const StyledHeader = styled.header`
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 1;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in forwards;
  padding: ${spacing.lg}px;
  @media ${breakpoint.lg} {
    padding: ${spacing.xl}px;
  }
  height: ${headerHeight}px;
  justify-content: space-between;
  align-items: center;
`

const Wrapper = styled.nav`
  display: flex;
  position: relative;
  & > :first-child {
    margin-right: ${spacing.md}px;
    display: none;
    z-index: 2;
    @media ${breakpoint.md} {
      display: block;
    }
  }
  & ul {
    z-index: 2;
  }
`

const Header: FC = () => {
  return (
    <StyledHeader>
      <Link
        to="/"
        title="Home"
        aria-label="Go to startpage"
        style={{ zIndex: 3 }}
      >
        <LogoIcon />
      </Link>
      <Wrapper aria-label="main navigation">
        <Social />
        <Menu />
      </Wrapper>
    </StyledHeader>
  )
}

export default Header
