import React, { FC } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ListItem } from "./Menu"
import styled from "styled-components"
import { useNavigation } from "../hooks/navigation"
import { spacing } from "../styles/spacing"
import { LogoWithTextIcon } from "./Icons"
import Social from "./Social"
import { breakpoint } from "../styles/breakpoints"
import { ContentContainer } from "./Shared/Container"

const StyledFooter = styled.footer`
  postition: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing.lg}px;
  & div:first-child {
    display: none;
  }
  flex-direction: column;
  @media ${breakpoint.lg} {
    flex-direction: row;
    & div:first-child {
      display: block;
    }
    & > div {
      width: 33%;
    }
  }
  & a {
    display: inline-block;
  }
`

const ContactWrapper = styled.div`
  text-align: center;
  @media ${breakpoint.lg} {
    text-align: right;
  }
  & > :not(:last-child) {
    margin-bottom: ${spacing.sm}px;
  }
  & > div:last-child {
    margin-top: ${spacing.xs}px;
  }
`

const Footer: FC = () => {
  const { nodes } = useNavigation()
  const { allWp } = useStaticQuery(graphql`
    query Footer {
      allWp {
        nodes {
          themeGeneralSettings {
            themeSettings {
              telephone
              facebook
              email
              instagram
              youtube
            }
          }
        }
      }
    }
  `)

  const telephoneNumber =
    allWp.nodes[0].themeGeneralSettings.themeSettings.telephone

  return (
    <ContentContainer style={{ maxWidth: 1920 }}>
      <StyledFooter>
        <div>
          <h4 style={{ marginBottom: `${spacing.xs}px` }}>Sidkarta</h4>
          <ul>
            {nodes.map(item => (
              <ListItem key={item.id} item={item} />
            ))}
          </ul>
        </div>
        <Link
          data-sal="zoom-in"
          data-sal-easing="ease"
          to="/"
          aria-label="Navigate to startpage"
          style={{ display: "inline-block" }}
        >
          <LogoWithTextIcon />
        </Link>
        <ContactWrapper>
          <h4>Mighty Event</h4>
          <p>
            Storgatan 10b
            <br /> 39232 Kalmar
          </p>
          <a href={`tel:${telephoneNumber}`}>{telephoneNumber}</a>
          <br />
          <a href="mailto: info@mightyevent.se">info@mightyevent.se</a>
          <Social />
        </ContactWrapper>
      </StyledFooter>
    </ContentContainer>
  )
}

export default Footer
