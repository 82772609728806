import React, { FC, useContext } from "react"
import styled from "styled-components"
import { spacing } from "../styles/spacing"
import Header from "./Header"
import { colors } from "../styles/colors"
import Footer from "./Footer"
import { AppContext } from "../context"
import { breakpoint } from "../styles/breakpoints"

const SiteWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: ${colors.black};
  margin: 0 auto;
  padding-bottom: ${spacing.sm}px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const MainWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 0;
  @media ${breakpoint.md} {
    min-height: calc(100vh - ${spacing.xl}px);
  }
  @media ${breakpoint.lg} {
    min-height: calc(100vh - ${spacing.xl * 1.5}px);
  }
  padding-top: ${spacing.xl * 2.5}px;
  & > :not(:last-child) {
    margin-bottom: ${spacing.xl}px;
  }
`

export const Wrapper = styled.div`
  padding: 0 ${spacing.sm}px;
  @media ${breakpoint.md} {
    padding: 0;
  }
`

const Layout: FC = ({ children }) => {
  const context = useContext(AppContext)
  return (
    <SiteWrapper style={{ overflow: context.menuOpen ? "hidden" : null }}>
      <Header />
      <MainWrapper>{children}</MainWrapper>
      <Footer />
    </SiteWrapper>
  )
}

export default Layout
