import { keyframes } from "styled-components"

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`
