import React, { FC } from "react"

export const MenuIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12H21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6H21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18H21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LogoIcon: FC = () => {
  return (
    <svg
      width="35"
      height="48"
      viewBox="0 0 35 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M25.4042 13.6239L17.0256 22.5474L8.64697 13.6239H0.0877686V47.8982L7.13181 41.8416V21.4997L17.0889 31.9094L27.0458 21.4997V41.8416L33.931 47.8982V13.6239H25.4042Z"
          fill="white"
        />
        <path
          d="M7.27115 10.6878L0.0877686 4.05023V10.7173L7.27115 10.6878Z"
          fill="white"
        />
        <path
          d="M26.7477 10.6878L33.9309 4.05023V10.7173L26.7477 10.6878Z"
          fill="white"
        />
        <path
          d="M17.0255 4.00775L12.4552 10.6743H21.5957L17.0255 4.00775Z"
          fill="white"
        />
        <path
          d="M0.0877686 0.00341702V2.98535C0.93517 2.9393 1.36903 2.29045 1.36903 1.49412C1.36903 0.697803 0.93517 0.0489507 0.0877686 0.00341702Z"
          fill="white"
        />
        <path
          d="M34.0898 0.00341702V2.98535C33.0835 2.9393 32.8083 2.29045 32.8083 1.49412C32.8083 0.697803 33.0835 0.0489507 34.0898 0.00341702Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="34.1384" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LogoWithTextIcon: FC = () => {
  return (
    <svg
      width="101"
      height="174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip1)" fill="#fff">
        <path d="M10.845 166.171l-3.02-11.668H0v19.175h4.873v-14.623l3.749 14.623h4.412l3.755-14.623v14.623h4.874v-19.175h-7.821l-2.997 11.668zM95.133 154.503l-3.888 6.468-3.882-6.468H60.062v6.71h-6.503v-6.71h-5.952v19.175h5.952v-7.757h6.503v7.757h5.978v-14.44h5.308v14.44H77.3v-14.44h6.069v-.851l4.887 7.26v8.031h5.964v-8.031l7.489-11.144h-6.575zM38.216 166.732h3.98v1.804c-.781.462-1.472.791-2.077.983a6.22 6.22 0 01-1.903.286c-1.403 0-2.495-.443-3.28-1.333-.784-.888-1.175-2.372-1.175-4.447 0-1.954.388-3.381 1.162-4.285.776-.902 1.815-1.351 3.121-1.351.876 0 1.596.19 2.161.575.564.382.965.937 1.202 1.658l.04-.004h5.693l.008-1.014c-.35-1.237-.874-2.262-1.57-3.068-.696-.807-1.572-1.402-2.628-1.784-1.056-.385-2.647-.576-4.775-.576-2.208 0-3.963.31-5.268.929-1.674.802-2.942 1.977-3.81 3.523a9.56 9.56 0 00-.799 1.876v-6.001h-5.964v19.175h5.965v-6c.18.593.408 1.159.686 1.696.792 1.536 1.91 2.691 3.35 3.466 1.44.778 3.305 1.165 5.59 1.165 1.857 0 3.463-.217 4.815-.648 1.353-.432 2.91-1.245 4.67-2.439v-8.174h-9.194v3.988zM54.724 4.543c0 2.508-1.818 4.542-4.058 4.542-2.242 0-4.06-2.034-4.06-4.542 0-2.51 1.818-4.543 4.06-4.543 2.24 0 4.058 2.034 4.058 4.543zM75.641 41.39L50.666 68.497 25.69 41.39H.177v104.117l20.997-18.399V65.315l29.68 31.622 29.68-31.622v61.793l20.524 18.399V41.39H75.641zM21.59 32.47L.176 12.308V32.56l21.412-.09zM79.647 32.47l21.412-20.163V32.56l-21.412-.09zM50.666 12.178L37.043 32.43h27.246L50.666 12.178zM.177.014v9.059c2.526-.14 3.819-2.111 3.819-4.53 0-2.42-1.293-4.39-3.82-4.529zM101.532.014v9.059c-3-.14-3.82-2.111-3.82-4.53 0-2.42.82-4.39 3.82-4.529z" />
      </g>
      <defs>
        <clipPath id="clip1">
          <path fill="#fff" d="M0 0h101v174H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const FacebookIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
        stroke="#FBFBFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const InstagramIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0002 11.37C16.1236 12.2022 15.9815 13.0522 15.594 13.799C15.2065 14.5458 14.5933 15.1514 13.8418 15.5297C13.0903 15.9079 12.2386 16.0396 11.408 15.9059C10.5773 15.7723 9.80996 15.3801 9.21503 14.7852C8.62011 14.1902 8.22793 13.4229 8.09426 12.5922C7.9606 11.7615 8.09226 10.9099 8.47052 10.1584C8.84878 9.40685 9.45438 8.79374 10.2012 8.40624C10.948 8.01874 11.7979 7.87658 12.6302 8C13.4791 8.12588 14.265 8.52146 14.8719 9.1283C15.4787 9.73515 15.8743 10.5211 16.0002 11.37Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 6.5H17.51"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const MailIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6L12 13L2 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PhoneIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9999 16.92V19.92C22.0011 20.1985 21.944 20.4742 21.8324 20.7294C21.7209 20.9845 21.5572 21.2136 21.352 21.4019C21.1468 21.5901 20.9045 21.7335 20.6407 21.8227C20.3769 21.9119 20.0973 21.9451 19.8199 21.92C16.7428 21.5856 13.7869 20.5342 11.1899 18.85C8.77376 17.3147 6.72527 15.2662 5.18993 12.85C3.49991 10.2412 2.44818 7.271 2.11993 4.18001C2.09494 3.90347 2.12781 3.62477 2.21643 3.36163C2.30506 3.09849 2.4475 2.85669 2.6347 2.65163C2.82189 2.44656 3.04974 2.28271 3.30372 2.17053C3.55771 2.05834 3.83227 2.00027 4.10993 2.00001H7.10993C7.59524 1.99523 8.06572 2.16708 8.43369 2.48354C8.80166 2.79999 9.04201 3.23945 9.10993 3.72001C9.23656 4.68007 9.47138 5.62273 9.80993 6.53001C9.94448 6.88793 9.9736 7.27692 9.89384 7.65089C9.81408 8.02485 9.6288 8.36812 9.35993 8.64001L8.08993 9.91001C9.51349 12.4136 11.5864 14.4865 14.0899 15.91L15.3599 14.64C15.6318 14.3711 15.9751 14.1859 16.3491 14.1061C16.723 14.0263 17.112 14.0555 17.4699 14.19C18.3772 14.5286 19.3199 14.7634 20.2799 14.89C20.7657 14.9585 21.2093 15.2032 21.5265 15.5775C21.8436 15.9518 22.0121 16.4296 21.9999 16.92Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const YoutubeIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5401 6.42C22.4213 5.94541 22.1794 5.51057 21.8387 5.15941C21.4981 4.80824 21.0708 4.55318 20.6001 4.42C18.8801 4 12.0001 4 12.0001 4C12.0001 4 5.12008 4 3.40008 4.46C2.92933 4.59318 2.50206 4.84824 2.16143 5.19941C1.8208 5.55057 1.57887 5.98541 1.46008 6.46C1.1453 8.20556 0.991319 9.97631 1.00008 11.75C0.988863 13.537 1.14285 15.3213 1.46008 17.08C1.59104 17.5398 1.83839 17.9581 2.17823 18.2945C2.51806 18.6308 2.9389 18.8738 3.40008 19C5.12008 19.46 12.0001 19.46 12.0001 19.46C12.0001 19.46 18.8801 19.46 20.6001 19C21.0708 18.8668 21.4981 18.6118 21.8387 18.2606C22.1794 17.9094 22.4213 17.4746 22.5401 17C22.8524 15.2676 23.0064 13.5103 23.0001 11.75C23.0113 9.96295 22.8573 8.1787 22.5401 6.42V6.42Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 15.02L15.5 11.75L9.75 8.48001V15.02Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SendIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="22" y1="2" x2="11" y2="13"></line>
      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
    </svg>
  )
}

export const CalenderIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2.66666H3.33333C2.59695 2.66666 2 3.26361 2 3.99999V13.3333C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3333V3.99999C14 3.26361 13.403 2.66666 12.6667 2.66666Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6666 1.33334V4.00001"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33337 1.33334V4.00001"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 6.66666H14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SearchIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14L11.1 11.1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
