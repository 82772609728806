export const size = {
  xs: 320,
  sm: 375,
  md: 425,
  lg: 768,
  xl: 1024,
  xxl: 1100,
  xxxl: 1500,
}

export const breakpoint = {
  xs: `(min-width: ${size.xs}px)`,
  sm: `(min-width: ${size.sm}px)`,
  md: `(min-width: ${size.md}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xl: `(min-width: ${size.xl}px)`,
  xxl: `(min-width: ${size.xxl}px)`,
  xxxl: `(min-width: ${size.xxxl}px)`,
}
