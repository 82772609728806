import React, { FC } from "react"
import styled from "styled-components"
import {
  InstagramIcon,
  YoutubeIcon,
  FacebookIcon,
  PhoneIcon,
  MailIcon,
} from "./Icons"
import { spacing } from "../styles/spacing"
import { Link, useStaticQuery, graphql } from "gatsby"

const StyledList = styled.div({
  "& a": { display: "inline-block" },
  "& :not(:last-child)": {
    marginRight: `${spacing.sm}px`,
  },
})

const Social: FC = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            themeSettings {
              telephone
              facebook
              email
              instagram
              youtube
            }
          }
        }
      }
    }
  `)

  const {
    allWp: { nodes: nodes },
  } = data

  const {
    email,
    youtube,
    facebook,
    telephone,
    instagram,
  } = nodes[0].themeGeneralSettings.themeSettings

  return (
    <StyledList>
      {instagram ? (
        <a href={instagram} target="_blank">
          <InstagramIcon />
        </a>
      ) : null}
      {youtube ? (
        <a href={youtube} target="_blank">
          <YoutubeIcon />
        </a>
      ) : null}
      {facebook ? (
        <a href={facebook} target="_blank">
          <FacebookIcon />
        </a>
      ) : null}

      {telephone ? (
        <a href={`tel: ${telephone}`}>
          <PhoneIcon />
        </a>
      ) : null}

      <Link to="/#contact-form">
        <MailIcon />
      </Link>
    </StyledList>
  )
}

export default Social
