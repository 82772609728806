// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../src/templates/Archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../src/templates/Blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */),
  "component---src-templates-event-tsx": () => import("./../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-film-tsx": () => import("./../src/templates/Film.tsx" /* webpackChunkName: "component---src-templates-film-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-photos-tsx": () => import("./../src/templates/Photos.tsx" /* webpackChunkName: "component---src-templates-photos-tsx" */)
}

