import React, { SFC, useState } from "react"

interface IDefaultStateProps {
  menuOpen: boolean
  handleMenuOpen: () => React.Dispatch<React.SetStateAction<boolean>> | void
  handleMenuClose: () => React.Dispatch<React.SetStateAction<boolean>> | void
}

const defaultState: IDefaultStateProps = {
  menuOpen: false,
  handleMenuOpen: () => {},
  handleMenuClose: () => {},
}
const AppContext = React.createContext(defaultState)

const AppProvider: SFC = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  return (
    <AppContext.Provider value={{ menuOpen, handleMenuOpen, handleMenuClose }}>
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider as default, AppContext }
