import React, { FC, useContext } from "react"
import { Link } from "gatsby"
import { useNavigation } from "../hooks/navigation"
import styled from "styled-components"
import ClickAwayListener from "react-click-outside"
import { MenuIcon } from "./Icons"
import { AppContext } from "../context"
import { colors } from "../styles/colors"
import { spacing } from "../styles/spacing"
import { typography } from "../styles/typography"
import { breakpoint } from "../styles/breakpoints"
import Social from "./Social"

const ListWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  transition: opacity 0.25s ease-out;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black};
  & :not(:last-child) {
    margin-right: ${spacing.md}px;
  }

  & div div {
    margin-top: ${spacing.xl}px;

    @media ${breakpoint.md} {
      display: none;
    }
  }
`

const StyledList = styled.ul`
  position: relative;
  font-family: ${typography.heading};
  font-weight: bold;
  hyphens: manual;
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  font-size: ${typography.size.xl};
  @media ${breakpoint.md} {
    font-size: ${typography.size.xxl};
  }
  @media ${breakpoint.lg} {
    font-size: ${typography.size.xxxl};
  }
  @media (max-height: 900px) and (min-width: 768px) {
    font-size: ${typography.size.xxl};
  }
  & * {
    position: relative;
    hyphens: manual;
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
  }
`

const Menu: FC = () => {
  const context = useContext(AppContext)
  const { nodes } = useNavigation()

  return (
    <div>
      <div
        onClick={() => {
          context.menuOpen
            ? context.handleMenuClose()
            : context.handleMenuOpen()
        }}
        title="menu"
        id="menu"
        aria-label="open menu"
        style={{ cursor: "pointer", position: "relative", zIndex: 2 }}
      >
        <MenuIcon />
      </div>
      <ListWrapper
        style={{
          opacity: context.menuOpen ? 1 : 0,
          left: context.menuOpen ? 0 : "-150%",
          zIndex: context.menuOpen ? 1 : 0,
        }}
      >
        <ClickAwayListener
          onClickOutside={e => {
            const ClickOnMenuIcon = e.target.id === "menu"
            if (ClickOnMenuIcon) return null
            context.menuOpen ? context.handleMenuClose() : null
          }}
        >
          <StyledList>
            {nodes.map(item => (
              <ListItem key={item.id} item={item} />
            ))}
          </StyledList>
          <Social />
        </ClickAwayListener>
      </ListWrapper>
    </div>
  )
}

export function ListItem({ item }) {
  const context = useContext(AppContext)
  return (
    <li>
      <Link
        to={`${item.url.replace("http://localhost:8000", "")}`}
        onClick={() => (context.menuOpen ? context.handleMenuClose() : null)}
        aria-label={`Navigate to ${item.label}`}
        title={item.label}
      >
        {item.label}
      </Link>
    </li>
  )
}

export default Menu
