export const typography = {
  heading: "Helvetica Neue,Helvetica,Arial,sans-serif",
  size: {
    xxxl: "100px",
    xxl: "56px",
    xl: "44px",
    lg: "36px",
    md: "24px",
    sm: "18px",
    xs: "14px",
  },
}
