import React from "react"
import Layout from "./src/components/Layout"
import AppProvider from "./src/context"
import "./src/styles/main.scss"

export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}

export const wrapPageElement = ({ element, ...restProps }, ...args) => {
  if (
    element.props &&
    element.props.children &&
    element.props.children.key &&
    element.props.children.key === "/404.html"
  )
    return
  return (
    <Layout name="wrapPageElement" props={restProps} args={args} mode="browser">
      {element}
    </Layout>
  )
}
