import { useStaticQuery, graphql } from "gatsby"

interface IDataProps {
  wpMenu: {
    menuItems: {
      nodes: [
        {
          id: string
          label: string
          url: string
          order: number
        }
      ]
    }
  }
}
export const useNavigation = () => {
  const {
    wpMenu: { menuItems },
  }: IDataProps = useStaticQuery(graphql`
    query MyQuery {
      wpMenu(slug: { eq: "menu" }) {
        menuItems {
          nodes {
            id
            order
            label
            url
          }
        }
      }
    }
  `)
  return menuItems
}
